.ripple {
    position: relative;
}

.ripple::after {
    content: ' ';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    transition: all 0.4s;
    background: transparent radial-gradient(circle, #1C1C1E 50%, transparent 50%) no-repeat center;
    background-size: 210%;
    border-radius: 20px;  /*todo refactoring so that it can be reused*/
}

.ripple:active::after {
    opacity: 0.3;
    transition: all 0s;
    background-size: 50%;
}
