@tailwind base;

/* Write your own custom base styles here */

/* Start purging... */
@tailwind components;
/* Stop purging. */

@font-face {
  font-family: 'DIN Next LT Pro';
  src: url(../fonts/NextLight.woff) format('woff'),
    url(../fonts/NextLight.woff2) format('woff2');
  font-weight: 300;
  font-display: fallback;
}

@font-face {
  font-family: 'DIN Next LT Pro';
  src: url(../fonts/NextNormal.woff) format('woff'),
    url(../fonts/NextNormal.woff2) format('woff2');
  font-weight: 400;
  font-display: fallback;
}

@font-face {
  font-family: 'DIN Next LT Pro';
  src: url(../fonts/NextBold.woff) format('woff'),
    url(../fonts/NextBold.woff2) format('woff2');
  font-weight: 500;
  font-display: fallback;
}

@font-face {
  font-family: 'DIN Next LT Pro';
  src: url(../fonts/NextBlack.woff) format('woff'),
    url(../fonts/NextBlack.woff2) format('woff2');
  font-weight: 900;
  font-display: fallback;
}

@font-face {
  font-family: 'DIN Next LT Pro';
  src: url(../fonts/NextBlack.woff) format('woff'),
    url(../fonts/NextBlack.woff2) format('woff2');
  font-weight: 700;
  font-display: fallback;
}

/* Start purging... */
@tailwind utilities;
/* Stop purging. */

html,
body {
  padding: 0;
  margin: 0;
  user-select: none;
  font-family: 'DIN Next LT Pro', -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
  background: #1c1c1e;
  height: 100%;
  overflow: hidden;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  -webkit-tap-highlight-color: transparent;
}

html {
  top: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

body {
  top: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.detail > h6 {
  padding-bottom: 0.5rem;
  font-size: 1.125rem;
  font-weight: 900;
  line-height: 1.1666;
}

.detail > h6:not(:first-child) {
  padding-top: 1.5rem;
}

.detail > ul {
  @apply list-disc ml-5 mt-2;
}
.detail > ul > li:not(:first-child) {
  @apply mt-1;
}

.usefulInfos > ul > li {
  position: relative;
  margin-left: 20px;
}
.usefulInfos > ul > li:before {
  content: '-';
  position: absolute;
  left: -20px;
}
.usefulInfos > p > a {
  text-decoration: underline;
}

.usefulInfos > p {
  padding-bottom: 20px;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.logo-animation {
  width: 100%;
  opacity: 0;
  animation-duration: 3000ms;
  animation-name: logoAnimation;
  /*animation-delay: 10000ms;*/
  animation-iteration-count: infinite;
  transform-origin: left;
}

.logo-fadein {
  opacity: 0;
  animation: logoFadeIn 250ms cubic-bezier(0, 0, 0.2, 1);
  animation-fill-mode: forwards;
  animation-delay: 1500ms;
}

.on-boarding {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background-color: #e4bf61;
  opacity: 0;
  animation: onboarding 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

.hyphens-auto {
  hyphens: auto;
}

@keyframes logoFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes onboarding {
  0% {
    transform: scale3d(1, 1, 1);
    opacity: 0.5;
  }
  100% {
    transform: scale3d(5, 5, 1);
    opacity: 0;
  }
}

@keyframes logoAnimation {
  0% {
    transform: scale3d(0, 1, 1);
    opacity: 1;
  }
  30% {
    transform: scale3d(1, 1, 1);
  }
  32% {
    opacity: 1;
  }
  33% {
    opacity: 0;
  }
}
